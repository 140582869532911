input.inline-input {
    background-color: transparent;
    border: 0;
    width: 60px;
    height: 15px;
}

input.inline-input:hover, input.inline-input:focus {
    background-color: #d3d3d3;
    border-radius: 15px;
    cursor: pointer;
    /*width: 100px;*/
}