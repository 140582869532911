.carousel .control-next.control-arrow:before {
  content: '';
  border: solid #0135AD;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  content: '';
  border: solid #0135AD;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}