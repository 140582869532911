.content-table-cassette table,
.content-table-cassette tr,
.content-table-cassette td {
  font-family: "Arial";
  font-size: small;
}
a.comment-indicator:hover + comment {
  background: #ffd;
  position: absolute;
  display: block;
  border: 1px solid black;
  padding: 0.5em;
}
a.comment-indicator {
  background: red;
  display: inline-block;
  border: 1px solid black;
  width: 0.5em;
  height: 0.5em;
}
comment {
  display: none;
}
.th-header {
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  border-left: 2px solid #000000;
  border-right: 2px solid #000000;
}
