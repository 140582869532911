/*body, html {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    height: 100%;*/
/*}*/
.mybox {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
}
.left-box{
    flex: 30%;
    background-color: black;
    /*padding-bottom: 50px;*/
    color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.rigth-box{
    flex: 60%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.rigth-children{
    display: flex;
    flex-direction: column;
    /*margin-top: 100px;*/
    /*margin-left: 20px;*/
    /*margin-right: 20px;*/
}

.left-children{
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-left: 30px;
    margin-right: 30px;
}
.login-logo{
    margin-bottom: 30px;
}
.presentation-text{
    font-size: 20px;
    font-family: 'helviticaneue';
    margin-bottom: 40px;
    width: 420px;
}

.audit-text {
    font-size: 30px;
    font-family: 'polySans';
    margin-bottom: 30px;
}
.screenshoot-img{
    margin-bottom: 20px;
}
.foot-box{
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.foot-text {
    flex: 50%;
    font-size: 15px;
    font-family: 'helviticaneue';
}
.login-fc{
    width: 370px;
    margin-top: 10px;
    border-radius: 35px;
    background-color: #F5F5F5;
    border: none;
    padding: 15px;
}
.rigth-logo-box{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
/*.password-input{*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    width: 370px;*/
/*    border-radius: 35px;*/
/*    background-color: #F5F5F5;*/
/*    border: none;*/
/*    padding: 20px;*/
/*}*/
#password-addon {
    padding-top: 12px;
    padding-right: 25px;
}
.log-btn:disabled,
.log-btn[disabled]{
    border: none;
    background-color: #cccccc;
    color: #666666;
}
.log-btn {
    /*width: 290px;*/
    height: 45px;
    border: none;
    background-color:#B7E806 ;
    /*margin-bottom: 20px;*/
}
.log-btn:hover {
    background-color:#cfe386;
}

@font-face {
    font-family: "polySans";
    src: url('../font/polySans/03bd7255184e8e8ad4d2a521fa920b6c.woff') format("woff"),
     url('../font/polySans/03bd7255184e8e8ad4d2a521fa920b6c.woff2') format("woff2");
}

@font-face {
    font-family: "helviticaneue";
    src: url("../font/helvitacaneue/HelveticaNeueLight.otf");
}


/*.login-form{*/
/*    margin-top: 40px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/
/*.form-text ,.checkbox-text{*/
/* font-size: 15px;*/
/* font-family: 'helviticaneue';*/
/* font-weight: 600;*/
/* margin-bottom: 20px;*/
/*}*/
/*input:focus {*/
/*    outline:none;*/
/*}*/
/*.psw-text{*/
/*    margin-top: 20px;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/
/*.mdp-oublie{*/
/*    font-size: 15px;*/
/*    font-family: 'helviticaneue';*/
/*    color: #585858;*/
/*}*/
/*.mdp{*/
/*    width: 370px;*/
/*    border: none;*/
/*    background-color: #F5F5F5;*/
/*    border-radius: 35px;*/
/*}*/
/*.se-souvenir-box{*/
/*    margin-top: 20px;*/
/*}*/
/*.checkbox-input {*/
/*    margin-right: 10px;*/
/*    transform: scale(1.5);*/
/*    border: #585858;*/
/*    width: 20px;*/
/*    background-color: aqua;*/
/*}*/
/*.btn-box {*/
/*    margin-top: 20px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/
/*.input-btn {*/
/*    width: 290px;*/
/*    height: 45px;*/
/*    border: none;*/
/*    background-color:#AAAAAA ;*/
/*    margin-bottom: 20px;*/
/*}*/
