.controls label {
    font-weight: bold;
    padding-left: 10px;
}

button.crop-btn {
    z-index: 2;
    cursor: pointer;
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 10px 0;
}

.cropped-image {
    height: 600px;
    width: auto;
}

.cropped-image-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

._coverImage-holder {
    padding: 25px 40px;
    background-color: black;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.crop .container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.crop .crop-container {
    height: 600px;
    width: 600px;
}

.controls {
    display: flex;
    flex-direction: column;
    width: 330px;
    position: absolute;
    /* bottom: -45px; */
}
